import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {NavBar} from './components/NavBar';
import {Banner} from './components/Banner';
import {Skills} from './components/Skills';
import ProjectTimeline from './components/ProjectTimeline';
import {Contact} from './components/Contact';
import {Footer} from './components/Footer';
import {ProfessionalExperience} from './components/ProfessionalExperience/ProfessionalExperience';


function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <ProfessionalExperience />
      {/* <Projects /> */}
      <ProjectTimeline />
      <Skills />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
