import { useState } from 'react';
import colorSharp from '../../assets/img/color-sharp.png';
import ProfessionalExperienceCard from './ProfessionalExperienceCard';

export const ProfessionalExperience = () => {
  const [activeLink, setActiveLink] = useState('0');

  const onUpdateActiveLink = (value) => {
    if (activeLink === value) {
      setActiveLink('0');
      return;
    }
    setActiveLink(value);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const data = [
    {
      company: 'Amazon',
      title: 'Software Engineer Intern',
      dates: ['May 2022 - August 2022'],
      height: 6,
      body: (
        <>
          <ul>
            <li>
              Implemented an email automation service using AWS CDK, Lambda,
              Route53, S3, SES, DynamoDB, CloudWatch, EC2, and SQS, which
              significantly reduced human error and prevented a 48-hour lockup
              of $70 million in funds
            </li>
            <li>
              Collaborated with 5 nationwide banks to streamline their email
              formats and scheduling requirements, and facilitated the creation
              of a standardized solution
            </li>
            <li>
              Documented the entire project, including architecture,
              implementation, and maintenance procedures, to preserve project
              details and promote long-term sustainability
            </li>
          </ul>
        </>
      ),
    },
    {
      company: 'Amazon',
      title: 'Software Engineer Intern',
      dates: ['May 2021 - August 2021'],
      height: 5,
      body: (
        <>
          <ul>
            <li>
              {' '}
              Developed update timeouts between transaction stages for the
              disbursement tracking backend, significantly reducing false alarms
            </li>
            <li>
              Implemented a timeout optimization mechanism that successfully
              eliminated at least one false alarm per week, resulting in a
              reduction of 52 hours of on-call operational load annually
            </li>
            <li>
              Effectively coordinated with a geographically diverse team in
              India to make necessary backend changes that enhanced system
              reliability
            </li>
          </ul>
        </>
      ),
    },
    {
      company: 'Pecos Solutions',
      title: 'Software Engineer',
      dates: ['September 2020 - May 2021'],
      height: 4,
      body: (
        <>
          <ul>
            <li>
              Leveraged React with styled-components to create a visually
              appealing and responsive webpage
            </li>
            <li>
              Designed and implemented a robust data pipeline capable of
              parsing, processing, and performing Optical Character Recognition
              (OCR) on over 2.5 million documents
            </li>
            <li>
              Utilized Figma and Photoshop to prototype design elements,
              ensuring a polished user interface
            </li>
          </ul>
        </>
      ),
    },
    {
      company: 'SAGA Labs @ UT Austin',
      title: 'Research Assistant - Game Programmer',
      dates: ['June 2021 - August 2021', 'June 2020 - August 2020'],
      height: 6,
      body: (
        <>
          <ul>
            <li>
              Developed and executed a complex Unity3D simulation to assist with
              research initiatives
            </li>
            <li>
              Orchestrated the integration of cutscene, animation, and dialogue
              systems using Yarn and Unity Animator to enhance user engagement
              and storytelling
            </li>
            <li>
              Incorporated Google Sheets API for streamlined data collection and
              management
            </li>
            <li>
              Collaborated with a cross-discipline team (of 10+ members) to
              develop and implement game mechanics, resulting in a seamless and
              immersive player experience
            </li>
          </ul>
        </>
      ),
    },
    {
      company: 'Ceremity',
      title: 'Software Engineer Intern',
      dates: ['June 2018 - August 2018'],
      height: 3,
      body: (
        <>
          <ul>
            <li>Developed a VR fitness game using Unity in C#</li>
            <li>Created a game tool using React</li>
            <li>Employed VRTK for rapid prototyping</li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <section className='professional' id='professional'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='professional-bx wow zoomIn'>
              <h2>Professional Experience</h2>
              <div className='expandable-list'>
                {data.map((item, index) => {
                  return (
                    <ProfessionalExperienceCard
                      activeLinkIdx={index + 1}
                      activeLink={activeLink}
                      onUpdateActiveLink={onUpdateActiveLink}
                      company={item.company}
                      title={item.title}
                      dates={item.dates}
                      body={item.body}
                      height={item.height}
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className='background-image-left' src={colorSharp} alt='Image' />
    </section>
  );
};
