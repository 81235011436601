import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import contactImg from '../assets/img/contact-img.svg';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import emailjs from '@emailjs/browser';

export const Contact = () => {
  const formInitialDetails = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    message: '',
    subject: '',
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(false);

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText('Sending...');
    // let response = await fetch("http://localhost:5000/contact", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json;charset=utf-8",
    //   },
    //   body: JSON.stringify(formDetails),
    // });
    setLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formDetails,
        process.env.REACT_APP_CONTACT_API_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus({ succes: true, message: 'Message sent successfully'});
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
          setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
          setLoading(false);
        }
      );
    setButtonText('Send');
    // let result = await response.json();
    setFormDetails(formInitialDetails);
    // if (result.code == 200) {
    //   setStatus({ succes: true, message: 'Message sent successfully'});
    // } else {
    //   setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
    // }
  };

  return (
    <section className='contact' id='connect'>
      <script
        type='text/javascript'
        src='https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js'
      ></script>
      <Container>
        <Row className='align-items-center'>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? 'animate__animated animate__zoomIn' : ''
                  }
                  src={contactImg}
                  alt='Contact Us'
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__fadeIn' : ''
                  }
                >
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          type='text'
                          value={formDetails.first_name}
                          placeholder='First Name'
                          onChange={(e) =>
                            onFormUpdate('first_name', e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          type='text'
                          value={formDetails.last_name}
                          placeholder='Last Name'
                          onChange={(e) =>
                            onFormUpdate('last_name', e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          type='email'
                          value={formDetails.email}
                          placeholder='Email Address'
                          onChange={(e) =>
                            onFormUpdate('email', e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          type='tel'
                          value={formDetails.phone}
                          placeholder='Phone No.'
                          onChange={(e) =>
                            onFormUpdate('phone', e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={12} className='px-1'>
                        <input
                          type='text'
                          value={formDetails.subject}
                          placeholder='Subject'
                          onChange={(e) =>
                            onFormUpdate('subject', e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} className='px-1'>
                        <textarea
                          rows='6'
                          value={formDetails.message}
                          placeholder='Message'
                          onChange={(e) =>
                            onFormUpdate('message', e.target.value)
                          }
                        ></textarea>
                        <button type='submit'>
                          <span>{buttonText}</span>
                        </button>
                      </Col>
                      {loading && (
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                      )}
                      {status.message && (
                        <Col size={12} sm={12} className='px-1'>
                          <p
                            className={
                              status.success === false ? 'danger' : 'success'
                            }
                          >
                            {status.message}
                          </p>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
