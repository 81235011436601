import React from 'react';
import './ProjectTimeline.css';

const ProjectTimelineCard = (props) => {
  const { title, subtitle, month, year, description, image, link } = props;
  const OpenLink = () => {
    if (link !== '')
      window.open(link, '_blank')
  }
  return (
    <div className={link !== '' ? 'demo-card demo-card-clickable' : 'demo-card'} onClick={OpenLink}>
      <div className='head'>
        <div className='number-box'>
          <span>
            <span className='small'>{month}</span>
            <br />
            {year}
          </span>
        </div>
        <h2>
          {title} <span className='small'>{subtitle}</span>
        </h2>
      </div>
      <div className='body'>
        <img src={image} alt='Graphic' />
        <p>
          {description}
        </p>
      </div>
    </div>
  );
};

export default ProjectTimelineCard;
