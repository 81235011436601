import './ProjectTimeline.css';
import ProjectTimelineCard from './ProjectTimelineCard';
import React from 'react';
import aslVR from '../assets/img/Projects/aslvr.jpg';
import minecraft from '../assets/img/Projects/minecraft.png'
import dreamCafe from '../assets/img/Projects/dreamcafe.png'
import godzilla from '../assets/img/Projects/godzilla.jpg'
import rushrush from '../assets/img/Projects/rushrushvr.png'
import bowgame from '../assets/img/Projects/bowgame.jpg'
import zombeevo from '../assets/img/Projects/zombeevo.jpg'
import sussy from '../assets/img/Projects/sussymonkeys.png'
import ocean from '../assets/img/Projects/oceansim.png'
import AAA from '../assets/img/Projects/aaa.jpg'
import rezeblok from '../assets/img/Projects/rezeblokv2.gif'
import cloutfit from '../assets/img/Projects/cloutfit.jpg'
import area51 from '../assets/img/Projects/area51.png'
import tubor from '../assets/img/Projects/tubor.jpg'
import reportit from '../assets/img/Projects/reportit.jpg'
import ceremity from '../assets/img/Projects/ceremity.jpg'
import wizball from '../assets/img/Projects/wizballgame.png'
import slingshot from '../assets/img/Projects/slingshot.gif'
import p5js from '../assets/img/Projects/p5js.png'
import backjox from '../assets/img/Projects/backjox.jpg'
import commentNomad from '../assets/img/Projects/comment-nomad.jpg'

const Data = [
  {
    title: 'Comment Nomad',
    subtitle: 'Chrome Extension',
    month: 'Sept',
    year: '2023',
    description:
      'Read your comments while watching your YouTube videos! Toggle your comment section to the right of the video and back!',
    image: commentNomad,
    link: 'https://chrome.google.com/webstore/detail/comment-nomad-move-youtub/ofhkahopkjfhgeifffppnojcoakiieph',
  },
  {
    title: 'Minecraft Server',
    subtitle: 'AWS EC2',
    month: 'Aug',
    year: '2023',
    description:
      'A cheap and accessible way to host a Minecraft Server. Comes out to $0.04 per hour the server is up!',
    image: minecraft,
    link: '',
  },
  {
    title: 'Dream Cafe',
    subtitle: 'Unity: 1st Place at Game Dev Battle',
    month: 'June',
    year: '2023',
    description:
      'Given the theme “Dream”, we came up with a Macaron serving cafe where you manage pipelines to produce different Macarons for your customers.',
    image: dreamCafe,
    link: 'https://shanlemon.itch.io/dream-cafe'
  },
  {
    title: 'Godzilla VR',
    subtitle: 'Unity',
    month: 'July',
    year: '2023',
    description:
      'Experience a civilian’s perspective in the original movie “Gojira”. VR Experience with full-body tracking and an NPC system.',
    image: godzilla,
    link: 'https://www.youtube.com/watch?v=rWcOcLmHn0A',
  },
  {
    title: 'RushRushVR',
    subtitle: 'Unity: WarioWare in VR',
    month: 'April',
    year: '2023',
    description:
      'Quest Standalone VR Game! Complete back-to-back minigames within the allotted time limit.',
    image: rushrush,
    link: 'https://www.youtube.com/watch?v=s41gW0tTUGs'
  },
  {
    title: 'The Bow Game',
    subtitle: 'Unity: Quest standalone VR Game',
    month: 'Feb',
    year: '2023',
    description:
      'Make your way through a forest path, using your bow and arrows to shoot hidden targets before they disappear. Aim for a high score!',
    image: bowgame,
    link: 'https://www.youtube.com/watch?v=Lo_TIrmspx4'
  },
  {
    title: 'Zombeevo',
    subtitle: 'Unreal Engine',
    month: 'Dec',
    year: '2022',
    description:
      'Zombeevo is a stage based shooter where the player automatically moves through the level and has to defeat the zombies, which are 3D scans of the developers!',
    image: zombeevo,
    link: 'https://www.youtube.com/watch?v=8Wo5WMYOAGY'
  },
  {
    title: 'Sussy Monkeys',
    subtitle: 'Unity: 1v1 Monkey Battle',
    month: 'Sept',
    year: '2022',
    description:
      'Two players compete by picking up bananas and either tossing banana molotovs to their opponent’s tree or using bananas as fuel for their fire extinguisher to clear fires on their own tree.',
    image: sussy,
    link: 'https://shanlemon.itch.io/sussy-monkeys'
  },
  {
    title: 'BackJox Games',
    subtitle: 'Unity, node.js, and React',
    month: 'Nov',
    year: '2021',
    description:
      'Remake of JackBox Games! Created using Unity, node.js server, and React frontend.',
    image: backjox,
    link: 'https://www.youtube.com/watch?v=29KSgvvPcaQ'
  },
  {
    title: 'Ocean Simulation',
    subtitle: 'Unity',
    month: 'May',
    year: '2021',
    description:
      'Gerstner Waves and Buoyancy Physics. I explore what it takes to generate ocean waves and apply buoyancy to a boat in Unity!',
    image: ocean,
    link: 'https://www.youtube.com/watch?v=e0gKrx5JEn8'
  },
  {
    title: 'AA Bathroom Queue',
    subtitle: 'Full-Stack Progressive Web App',
    month: 'Jan',
    year: '2021',
    description:
      'We created a queue for the bathroom on airlines to increase efficiency using React and AWS DynamoDB. We also created an in-house door simulation using an Arduino!',
    image: AAA,
    link: 'https://devpost.com/software/tbd-0jcmx7'
  },
  {
    title: 'Rewind Parkour',
    subtitle: 'Unity',
    month: 'Aug',
    year: '2020',
    description:
      'Speedrunner Parkour Game with time rewinding and grappling applities. Created our own BHOP algorithm similar to CS:GO!',
    image: rezeblok,
    link: 'https://buu-mm.itch.io/re-zeblock'
  },
  {
    title: 'Clout Fit',
    subtitle: 'Flutter and Firebase',
    month: 'Nov',
    year: '2019',
    description:
      'Cloutfit helps users pick out their clothes for the day by checking which clothes they have available and by checking the weather.',
    image: cloutfit,
    link: 'https://devpost.com/software/cloutfit-b70l2w'
  },
  {
    title: 'Area 51 Experience',
    subtitle: 'Unity VR',
    month: 'Sept',
    year: '2019',
    description:
      'Emerging Technology Award at EGADAS Game Jam. Experience the September 20th raid of Area 51 in VR. Using Leap Motion hand tracking!',
    image: area51,
    link: 'https://github.com/shanlemon/Area-51-Experience'
  },
  {
    title: 'ASL VR',
    subtitle: 'Unity: 1st Place at Klein Hacks',
    month: 'April',
    year: '2019',
    description:
      'ASL VR uses virtual reality and Leap motion tracking to predict which letter in the American Sign Language alphabet you are making to provide a learning experience.',
    image: aslVR,
    link: 'https://www.youtube.com/watch?v=ZbM6WzL9wSo'
  },
  {
    title: 'Tubor',
    subtitle: 'Flutter and Firebase',
    month: 'Jan',
    year: '2019',
    description:
      'Uber, but for tutoring. Tubor eliminates the need for expensive and inconvenient middlemen like Kumon and Mathnasium by connecting students directly to freelance tutors.',
    image: tubor,
    link: 'https://devpost.com/software/tubor'
  },
  {
    title: 'Report It',
    subtitle: 'Flutter and Firebase',
    month: 'Nov',
    year: '2018',
    description:
      'Three High School Students Win Second Place in College Hackathon! Report It helps citizens play a role in fighting crime by allowing them to report crimes quickly and anonymously.',
    image: reportit,
    link: 'https://github.com/shanlemon/report_it'
  },
  {
    title: 'AstroFit VR',
    subtitle: 'Unity',
    month: 'Jul',
    year: '2018',
    description:
      'AstroFit is a VR Rhythm Game to promote exercising. It takes place in space and features the player punching blocks representing asteroids with a pair of holographic boxing gloves.',
    image: ceremity,
    link: 'https://youtu.be/rBB2g8VOmso?si=o09dLP3BdafXuW5E&t=284'
  },
  {
    title: 'Wizball',
    subtitle: 'Unity',
    month: 'April',
    year: '2018',
    description:
      'Soccer, but with Wizards. Wizball is a soccer game where wizards use their unique spells to score the ball and win the game!',
    image: wizball,
    link: 'https://www.youtube.com/watch?v=zjs_dmcwv48'
  },
  {
    title: 'Slingshot',
    subtitle: 'Unity',
    month: 'Nov',
    year: '2017',
    description:
      'A simple game where the ball sticks to red surfaces and bounces off blue surfaces.',
    image: slingshot,
    link: 'https://shanlemon.itch.io/sling-shot-app-2017'
  },
  {
    title: 'P5.js Games',
    subtitle: 'P5.js',
    month: 'Aug',
    year: '2017',
    description:
      'A small collection of simple games written with P5.js. Games include Pong, Snake, Flappy Bird, Simon Says, Asteroids, and Stacker.',
    image: p5js,
    link: 'https://shanlemon.github.io/P5Games/'
  },
];

const ProjectTimeline = () => {
  return (
    <section id='timeline'>
      <h1>My Projects</h1>
      {/* <p class='leader'>Explore my projects all the way back to 2017!</p> */}
      <div className='grid'>
        {Data.map((data, i) => {
          return (
            <ProjectTimelineCard
              title={data.title}
              subtitle={data.subtitle}
              month={data.month}
              year={data.year}
              description={data.description}
              image={data.image}
              link={data.link}
              key={i}
            />
          );
        })}
      </div>
    </section>
  );
};

export default ProjectTimeline;
