import React from 'react';

const ProfessionalExperienceCard = (props) => {
  const { activeLinkIdx, activeLink, onUpdateActiveLink, company, title, dates, body, height} = props;

  const getHeightStyle = () => {
    switch (height) {
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';
      case 5:
        return 'five';
      case 6:
        return 'six';
      default:
        return '';
    }
  }

  return (
    <div
      className={
        activeLink === activeLinkIdx ? 'active expandable-item' : 'expandable-item'
      }
      onClick={() => onUpdateActiveLink(activeLinkIdx)}
    >
      <div className='expandable-header'>
        <div className='expandable-data'>
          <div className='expandable-data-child'>
            <div>
              <h3>{company}</h3>{' '}
            </div>
            <div>{title}</div>
          </div>
          <div className='expandable-data-child'>
            {dates &&
              dates.map((date, i) => {
                return <div key={i}>{date}</div>;
              })}

          </div>
        </div>
        <div className='expandable-icon'>
          <div className='line'></div>
          <div className='line'></div>
        </div>
      </div>
      <div className={'expandable-body ' + getHeightStyle() }>
        {body}
      </div>
    </div>
  );
};

export default ProfessionalExperienceCard;
